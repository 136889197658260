<template>
  <div class="news-dialog">
    <el-dialog  :fullscreen="dialogFull"  :visible.sync='dialogs' width="800px" :before-close="onClose">
      <template slot="title">
        <div class="avue-crud__dialog__header">
            <span>
              添加新闻
            </span>
          <div class="avue-crud__dialog__menu" @click="dialogFull? dialogFull=false: dialogFull=true">
            <i class="el-icon-full-screen"></i>
          </div>
        </div>
      </template>

      <el-form
      label-width="80px"
      :model="form"
      class="add-form"
      label-position="top"
      :rules="rules"
      ref="ruleForm">
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="新闻标题" prop='caption'>
              <el-input class='form-input' v-model="form.caption"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="作者名称" prop='writer'>
              <el-input class='form-input' v-model="form.writer"></el-input>
            </el-form-item>
          </el-col>
        </el-row >
        <el-form-item label="新闻文件" prop='page_cover' class='upload'>
          <FileUpload :files="form.page_cover" @extra-files="newsFile" />
        </el-form-item>
        <el-form-item label="内容" prop='text'>
          <Editor
            :init="setting"
            :value="form.text"
            v-model="form.text"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="mini" @click="onClose">取消</el-button>
        <el-button size="mini" type="primary" @click="onSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addNews } from '@/api/saasManage'
import Editor from '@tinymce/tinymce-vue'
import FileUpload from '@/manage-views/components/FileUpload.vue'
// import { Tag, RadioGroup } from 'element-ui';
import { getToken } from '@/utils/tools';
import { axios } from '@/apis/http/request';
export default {
  name:'AddDialog',
  components: {
    Editor,
    FileUpload,
  },
  props: {
     dialog:{
      type:Boolean,
      default:false,
    },
  },
  data() {
    // var validateAttach=(rule, value, callback)=>{
    //   console.log(rule)
    //   if(!this.form.page_cover.length){
    //       return callback(new Error('附件不能为空'))
    //   }else{
    //       return callback()
    //   }
    // }
    return {
      dialogs:false,
      dialogFull:false,
      form:{
        text:'', // 富文本框
        caption:'',//新闻标题
        page_cover:[],//文件
        writer:''
      },
      rules:{
        caption:[
          { required: true, message: '名称不能为空', trigger: 'blur' },
        ],
        text:[
          { required: true, message: '富文本内容不能为空', trigger: 'blur' },
        ],
        writer:[
          { required: true, message: '请输入作者名字', trigger: 'blur' },
        ],
        page_cover:[
         { required: true, message: '请输入新闻文件', type:'array' },
        ],
      },
      setting: {
        language: 'zh_CN',
        menubar: false, // 禁用菜单栏
        plugins: 'print preview searchreplace fullscreen autolink image link media code' +
          ' codesample table charmap hr pagebreak nonbreaking advlist lists textpattern help',
        toolbar: `bold italic underline strikethrough forecolor backcolor link autolink
        | alignleft aligncenter alignright alignjustify outdent indent bullist numlist
        | formatselect fontselect fontsizeselect lineheight
        | blockquote subscript superscript removeformat image media table
        | hr insertdatetime preview code codesample visualblocks searchreplace
        | undo redo fullscreen`,
        toolbar_mode: 'sliding',
        placeholder: '请输入正文',
        branding: false,
        autosave_ask_before_unload: false,
        font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;' +
          '苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;' +
          'Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;' +
          'Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;' +
          'Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;' +
          'Terminal=terminal,monaco;Times New Roman=times new roman,times;Verdana=verdana,geneva;Webdings=webdings;' +
          'Wingdings=wingdings,zapf dingbats;' +
          '知乎配置=BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, ' +
          'Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;' +
          '小米配置=Helvetica Neue,Helvetica,Arial,Microsoft Yahei,Hiragino Sans GB,Heiti SC,WenQuanYi Micro Hei,sans-serif',
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
        formats: {
          h1: { block: 'h1', classes: 'aiming-point' },
          h2: { block: 'h2', classes: 'aiming-point' },
          h3: { block: 'h3', classes: 'aiming-point' },
          h4: { block: 'h4', classes: 'aiming-point' },
          h5: { block: 'h5', classes: 'aiming-point' }
        },
        // 图片上传
        images_upload_handler: function (blobInfo, success, failure) {
          if (blobInfo.blob().size / 1024 / 1024 > 2) {
            failure('上传失败，图片大小请控制在 2M 以内')
          } else {
            const data = new FormData()
            data.append('file', blobInfo.blob())
            axios.request({url: `/api/mapi?__method_name__=file&token=${getToken()}`,	method: 'post',data: data}).then(res => {
              if (res.data.code === 200) {
                console.log(res)
                success(res.data.data.url.replace('_thumb', ''))
              } else {
                failure('上传失败')
              }
            })
          }
        },
        height: 260
      },
    };
  },
  watch: {
    dialog(newVal){
      this.dialogs = newVal;
    },
  },
  methods: {
    /**
     * @desc 文件上传事件
     */
    newsFile(file){
      this.form.page_cover = file 
      // this.$refs.ruleForm.validateField('pageCover');
    },
    /**
     * @desc: 保存
     */
    onSave(){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          addNews(this.form).then(res=>{
            if(res.data && res.data.code !== 200){
              return false;
            }
            this.$emit('dialogSave');
            this.form = {
              text:null, // 富文本框
              caption:'',//新闻标题
              page_cover:[], //新闻类型，
              writer:''
            };
          }).catch(() => {
            this.$message.error(`操作错误`);
          });
        } else {
          return false;
        }
      });
    },
    /**
     * @desc:关闭
     */
    onClose() {
      this.dialogs = false;
      this.form={
        text:null, // 富文本框
        caption:'',//新闻标题
        page_cover:[], //新闻类型
        writer:''
      };
      this.$emit('dialogClose');
    },
  },
};
</script>
<style lang="less" scoped>
.news-dialog{
  .add-form{
    height:410px;
    overflow:auto;
    padding-bottom: 10px;
    .upload{
      :deep(.el-form-item__label){
        padding: 0;
      }
    }
  }
}

</style>
<style lang="less" scoped>
@import "../../../../css/manageAdd.less";
</style>
