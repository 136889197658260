<template>
  <div class="news-main">
    <div class='main-title'>
      <div class="main-label">新闻动态</div>
      <div>
        <el-button v-if="judgingAPermission(['news.store'])" size='mini' type="primary" icon="el-icon-plus" @click="dialog=!dialog">新增</el-button>
        <el-popconfirm
          v-if="parentSelectId && parentSelectId.length && judgingAPermission(['news.delete'])" 
          class="delete-btn"
          title="确定要删除这个新闻吗？"
          @confirm='onSelectDelete()'
        >
          <el-button 
            slot="reference"
            size='mini' 
            type="danger" 
            icon="el-icon-delete-solid" >
          删除
          </el-button>
        </el-popconfirm>
      </div>
    </div>
    <el-table
    v-loading='loading'
    :data="configData"
    :header-cell-style="{background:'#fafafa'}"
    class="news-table"
    @selection-change="onSelectChange"
    >
      <el-table-column type="selection" :selectable="checkSelectable" width="55"></el-table-column>
      <el-table-column label="新闻作者" prop="writer" width="200px"></el-table-column>
      <el-table-column label="新闻标题" prop="caption"></el-table-column>
      <el-table-column label="创建时间" prop="created_at" align="left" width="150px"></el-table-column>
      <el-table-column label="操作"  align="center" width='200px'>
        <template #default="{ row }" >
          <el-button v-if="companyId == row.saas_company_id && judgingAPermission(['news.update'])" size="mini" type="text" @click="onEdit(row)">修改</el-button>
          <el-popconfirm
            v-if="companyId == row.saas_company_id && judgingAPermission(['news.delete'])"
            class="delete-btn"
            title="确定要删除这个新闻吗？"
            @confirm='onDelete(row)'
          >
            <el-button v-if="row.saas_company_id || isSuper" size="mini" type="text" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 页脚 -->
    <el-pagination
      @size-change="onSize"
      @current-change="onPage"
      :current-page="+pager.page"
      :page-sizes="[15, 20, 50, 100, 200]"
      :page-size="+pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="+pager.total"
    >
    </el-pagination>
    <!-- 编辑抽屉 -->
    <EditDrawer 
    :drawer='drawer' 
    :editData='editData' 
    @drawerClose='drawerClose' 
    @drawerSave='drawerSave'>
    </EditDrawer>
    <!-- 新增弹窗 -->
    <AddDialog 
    :dialog='dialog'
    @dialogClose='dialogClose' 
    @dialogSave='dialogSave' >
    </AddDialog>
  </div>
</template>

<script>
import { getNews, detNews } from '@/api/saasManage'
import { Popconfirm } from 'element-ui'
import EditDrawer from './EditDrawer.vue'
import AddDialog from './AddDialog.vue'
import { getCompanyId, judgingAPermission } from "@/utils/tools"
export default {
  components:{
    'el-popconfirm': Popconfirm,
    AddDialog,
    EditDrawer,
  },
  props: {},
  data() {
    return {
      judgingAPermission: judgingAPermission,
      companyId:getCompanyId(),
      isSuper:false, // 是否为超级管理员
      parentSelectId:[],
      loading:false,
      configData:[],
      drawer:false,
      dialog:false,
      editData:{},
      pager: {
        page: 1,
        size: 15,
        total: 0
      },
      search: '',
    };
  },
  methods: {
    /**
     * 设置禁止选择的数据
     */
    checkSelectable (row) {
      if(this.isSuper){
        return true;
      }
      let flag = true
      for (let i = 0; i < this.configData.length; i++) {
        if (!row.saas_company_id) { 
          flag = false
        } else {
          flag = true
        }
      }
      return flag
    },

    /**
     * @desc:关闭新增弹窗
     */
    dialogClose(){
      this.dialog = !this.dialog;
    },
    /**
     * @desc:新增弹窗点击保存按钮
     */
    dialogSave(){
      this.dialog = !this.dialog;
      this.getData();
    },
    /**
     * @desc:修改抽屉点击保存按钮
     */
    drawerSave(){
      this.drawer = !this.drawer;
      this.getData();
    },
    /**
     * @desc:关闭编辑抽屉
     */
    drawerClose(){
      this.drawer = !this.drawer;
    },
    /**
     * @desc:打开编辑弹窗
     */
    onEdit(row){
      this.editData = row;
      this.drawer = !this.drawer;
    },
    /**
     * @desc:普通删除
     */
    onDelete(row){
      detNews({id:[row.id]}).then((res)=>{
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.getData();
      }).catch(() => {
        this.$message.error(`操作错误`)
      });
    },
    /**
     * @desc: 批量删除
     */
    onSelectDelete(){
      detNews({id:this.parentSelectId}).then((res)=>{
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.getData();
      }).catch(() => {
        this.$message.error(`操作错误`)
      });
    },
    /**
     * @desc:筛选
     */
    onSearch(data){
      this.search = data;
      this.getData();
    },
    onRefresh(){
      this.search = '';
      this.getData();
    },
     /**
		 * @desc: 分页数量
		 * @param {Number} size
		 */
		onSize(size) {
      this.pager.page = 1;
      this.pager.size = size;
      this.getData();
		},
		/**
		 * @desc: 切换页码
		 * @param {Number} page
		 */
		onPage(page) {
      this.pager.page = page;
      this.getData();
		},
    /**
     * @desc:父表格选择时
     */
    onSelectChange(val){
      this.parentSelectId = [];
      val.forEach(data=>{
        this.parentSelectId.push(data.id);
      })
    },
    // /**
		//  * @desc: 得到公告管理列表
		//  */
    getData(){
      this.loading = true;
      let params = {
        page: this.pager.page,
        size: this.pager.size,
        name: this.search
      };
      getNews(params).then(res=>{
        if(res.status === 200 && res.data.code === 200){
          this.configData = res.data.data.data;
          this.pager = {
            page: res.data.data.current_page,
            size: res.data.data.per_page,
            total: res.data.data.total
          };
        }
        this.loading = false;
      }).catch(() => {
        this.$message.error(`操作错误`);
      });
    },
  },
  mounted() {
     this.getData();
     if(localStorage.getItem('company_id') == 0){
      this.isSuper = true;
     }
  }
};
</script>
<style lang='less'>
@import "../../../../css/manageMain.less";
.news-main {
  background-color: #fff;
  display: flex;
  overflow: auto;
  flex-direction: column;
  flex: 1;
  border-radius: 8px;
  .news-table{
    width: 100%;
    flex: 1;
    padding: 0 15px;
    overflow: auto;
  }
}
</style>